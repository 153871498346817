import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import axiosIns from '@axios'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import * as Sentry from "@sentry/vue";
import store from './store'

Vue.config.productionTip = false

if (['staging', 'production'].includes(process.env.VUE_APP_ENVIRONMENT)) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_DSN_SENTRY,
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^https:\/\/yourserver\.io\/api/, /^https:\/\/whatscupon\.com\//],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

new Vue({
  router,
  store,
  vuetify,
  axiosIns,
  render: h => h(App),
}).$mount('#app')
