import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-content-vertical-nav',{attrs:{"nav-menu-items":_vm.navMenuItemsToRender},scopedSlots:_vm._u([{key:"navbar",fn:function(ref){
var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive;
return [_c('div',{staticClass:"navbar-content-container",class:{'expanded-search': _vm.shallShowFullSearch}},[_c('div',{staticClass:"d-flex align-center"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c(VIcon,{staticClass:"me-3",on:{"click":toggleVerticalNavMenuActive}},[_vm._v(" "+_vm._s(_vm.icons.mdiMenu)+" ")]):_vm._e()],1),_c('div',{staticClass:"d-flex align-center ml-auto",staticStyle:{"max-width":"18rem"}},[_c(VSelect,{attrs:{"items":_vm.company_options,"hide-details":"true","label":"Empresa seleccionada","outlined":"","dense":""},on:{"change":_vm.changeCompany},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c(VListItem,[_c(VListItemContent,[_c(VTextField,{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"placeholder":"Buscar empresa","dense":"","hide-details":"true"},on:{"keyup":function($event){return _vm.filterCompanyList()}},model:{value:(_vm.companySearcherInput),callback:function ($$v) {_vm.companySearcherInput=$$v},expression:"companySearcherInput"}})],1)],1),_c(VDivider,{staticClass:"mt-2"})]},proxy:true}],null,true),model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1),_c('div',{staticClass:"d-flex align-center right-row"},[_c('app-bar-user-menu')],1)])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-space-between"},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(" COPYRIGHT © "+_vm._s(new Date().getFullYear())+" "),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"https://letscode.ar"}},[_vm._v(" Let's Code ")]),_c('span',{staticClass:"d-none d-md-inline"},[_vm._v(", All rights Reserved")])]),_c('div',{staticClass:"align-center d-none d-md-flex"})])]},proxy:true}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }