import { 
  mdiFrequentlyAskedQuestions, 
  mdiMessageBulleted, 
  mdiAccount, 
} from '@mdi/js'


export default [
  {subheader: 'USUARIO' },
  {title: 'Mensajes', icon: mdiMessageBulleted, to: 'messages'}, 
  {title: 'Perfil', icon: mdiAccount, to: 'profile'},
  { subheader: 'Otros' },
  {title: 'FAQ', icon: mdiFrequentlyAskedQuestions, to: 'faq'},
]
