import Vue from 'vue'
import VueRouter from 'vue-router'
import admin_private_url from '../middleware/admin-private-url';
import private_url from '../middleware/private-url';
import public_url from '../middleware/public-url';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'messages',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('@/views/Messages.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/credits',
    name: 'credits',
    component: () => import('@/views/Credits.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/channels',
    name: 'channels',
    component: () => import('@/views/Channels.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/Companies.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/stats',
    name: 'stats',
    component: () => import('@/views/Stats.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/Contacts.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/contact-batches',
    name: 'contact-batches',
    component: () => import('@/views/ContactBatches.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/Tags.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/Campaigns.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/ripener-text',
    name: 'ripener-text',
    component: () => import('@/views/RipenerText.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/ripener',
    name: 'ripener',
    component: () => import('@/views/Ripener.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  {
    path: '/blacklist',
    name: 'blacklist',
    component: () => import('@/views/Blacklist.vue'),
    meta: {
      middleware: admin_private_url,
      layout: 'content',
    },
  },
  // {
  //   path: '/send',
  //   name: 'send',
  //   component: () => import('@/views/Send.vue'),
  //   meta: {
  //     middleware: private_url,
  //     layout: 'content',
  //   },
  // },
  // {
  //   path: '/second-page',
  //   name: 'second-page',
  //   component: () => import('@/views/SecondPage.vue'),
  //   meta: {
  //     middleware: private_url,
  //     layout: 'content',
  //   },
  // },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      middleware: public_url,
      layout: 'blank',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('@/views/FAQ.vue'),
    meta: {
      middleware: private_url,
      layout: 'content',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },

})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router
