import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
// import session from './session'

Vue.use(Vuex)



const session = {
  namespaced: true,

  state: () => ({
    session: {
      access: '',
      refresh: '',
    },
    selected_company: null,
    localStorage: false,
    credits: 0,
    allCompaniesList: [],
  }),

  mutations: {
    SET_SESSION_DATA(state, data) {
      const session = {
        access: data.access? data.access : data.access,
        refresh: data.refresh,
        user: data.user,
      }
      state.session = session

      if (!data.alreadyLoaded) {
        state.localStorage = data.remember
        if (data.remember) {
          localStorage.setItem('session', JSON.stringify(session))
        } else {
          sessionStorage.setItem('session', JSON.stringify(session))
        }
        // console.log(this.commit)
        // dispatch('session/setSelectedCompany', data.selected_company)
        // this.commit('SET_SELECTED_COMPANY', data.selected_company)
      }
    },

    CLEAR_SESSION_DATA(state) {
      state.user = null

      state.selected_company = null,
      state.localStorage = false,
      state.credits = 0,
      localStorage.removeItem('selected_company')
      sessionStorage.removeItem('selected_company')
      sessionStorage.removeItem('session')
      localStorage.removeItem('session')
    },

    REFRESH_TKN(state, data) {
      state.session.access = data.access
      localStorage.setItem('session', JSON.stringify(state.session))
    },

    SET_SELECTED_COMPANY(state, company_id) {
      state.selected_company = company_id
      state.localStorage
        ? localStorage.setItem('selected_company', JSON.stringify(company_id))
        : sessionStorage.setItem('selected_company', JSON.stringify(company_id))
    },
    SET_CREDITS_VALUE(state, value) {
      state.credits = value
    },

    SET_ALL_COMPANIES_LIST(state, data) {
      state.allCompaniesList = data
    }
  },

  actions: {
    setSessionData({ commit }, session_data) {
      commit('SET_SESSION_DATA', session_data)
    },
    logoutUser({ commit }) {
      commit('CLEAR_SESSION_DATA')
    },

    setSelectedCompany({ commit }, company_id) {
      commit('SET_SELECTED_COMPANY', company_id)
    },
    setCredits({ commit }, value) {
      commit('SET_CREDITS_VALUE', value)
    },
    setAllCompaniesList({ commit }, data) {
      commit('SET_ALL_COMPANIES_LIST', data)
    }
  },

  getters: {
    logged: (state) => () => { return !! state.session },
    token: (state) => () =>{ return state.session?.access },
    refresh: (state) => () =>{ return state.session?.refresh },
    userData: (state) => () =>{ return state.session?.user },
    getUserRole: (state) => () =>{ return state.session?.user?.role },

    getSelectedCompany: (state) => () =>{ return state.selected_company },
    getCredits: (state) => () =>{ return state.credits },

    getAllCompaniesList: (state) => () =>{return state.allCompaniesList}
  },
}



export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    session: session,
  },
})
