import {
  mdiFrequentlyAskedQuestions,
  // mdiHomeOutline,
  mdiCellphoneWireless,
  mdiDomain,
  mdiMessageBulleted,
  mdiAccount,
  mdiChartBoxOutline,
  mdiAccountMultipleOutline,
  mdiAlphaCCircle,
  mdiAccountBox,
  mdiTagMultiple,
  mdiAccountMultiplePlus,
  mdiCalendarAlert,
  mdiCellphoneInformation,
  mdiCellphoneMessageOff,
  mdiCellphonePlay
} from '@mdi/js'


export default [
  {subheader: 'USUARIO' },
  // {title: 'Inicio', icon: mdiHomeOutline, to: 'home' },
  {title: 'Mensajes', icon: mdiMessageBulleted, to: 'messages'},
  {title: 'Estadisticas', icon: mdiChartBoxOutline, to: 'stats'},
  {title: 'Perfil', icon: mdiAccount, to: 'profile'},
  { subheader: 'CAMPAÑAS' },
  {title: 'Campañas', icon: mdiCalendarAlert, to: 'campaigns'},
  {title: 'Contactos', icon: mdiAccountBox, to: 'contacts'},
  {title: 'Lotes de importación', icon: mdiAccountMultiplePlus, to: 'contact-batches'},
  {title: 'Tags', icon: mdiTagMultiple, to: 'tags'},
  { subheader: 'MADURADOR' },
  {title: 'Modelos de mensaje', icon: mdiCellphoneInformation, to: 'ripener-text'},
  {title: 'Madurador', icon: mdiCellphonePlay, to: 'ripener'},
  {title: 'Black List', icon: mdiCellphoneMessageOff, to: 'blacklist'},
  { subheader: 'CONFIGURACIONES' },
  {title: 'Canales', icon: mdiCellphoneWireless, to: 'channels'},
  {title: 'Empresas', icon: mdiDomain, to: 'companies'},
  {title: 'Usuarios', icon: mdiAccountMultipleOutline, to: 'users'},
  {title: 'Créditos', icon: mdiAlphaCCircle, to: 'credits'},

  { subheader: 'Otros' },
  {title: 'FAQ', icon: mdiFrequentlyAskedQuestions, to: 'faq'},


]
