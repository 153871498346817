// axios
import axios from 'axios'
import Vue from 'vue'

const first_path = 'ripener/'

const instance = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000, // cambie default por que checkStatusAck() requiere más tiempo para la peticion
  headers: {
    'Content-Type': 'application/json',
  },
})

instance.interceptors.request.use(
  config => {
    // Do something before request is sent
    const sessionData = localStorage.getItem('session') || sessionStorage.getItem('session')
    const parsedData = JSON.parse(sessionData)
    const accessToken = parsedData?.access

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)


instance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error?.response?.data?.message === "token_not_valid") {
    console.log('token vencido')

    localStorage.removeItem('selected_company')
    sessionStorage.removeItem('selected_company')
    sessionStorage.removeItem('session')
    localStorage.removeItem('session')

    window.location.replace('login')
  }
  return Promise.reject(error);
});

/* PROFILE EPs */
instance.getUserDataForProfile = () => instance.get(`app/get_user_info`)
  .then(response => response.data)

instance.updateUserDataForProfile = form => instance.put(`app/get_user_info`, form)
  .then(response => response.data)

instance.changeUserPasswordProfile = form => instance.post(`app/change_password`, form)
  .then(response => response)

/* USER EPs */
instance.login = form => {
  return instance.post(`account/login/`, form)
    .then(response => response.data)
}

instance.getOneUserInfo = user_id => instance.get(`app/user/${user_id}`)
  .then(response => response.data)

instance.getAllUsers = () => instance.get(`app/user/users_list`)
  .then(response => response.data)

instance.getUsersForCreateCompany = () => instance.get(`app/user/usersdata_list`)
  .then(response => response.data)

instance.getUsersForCreditsMovements = company_id => instance.get(`app/user/usersprofile_list/${company_id}`)
  .then(response => response.data)

instance.createUserdataForCurrent = () => instance.post(`app/userdata`)
  .then(response => response.data)

instance.createUserdata = id => instance.post(`app/userdata/${id}`)
  .then(response => response.data)

instance.getUsersList = (data) => {
  let url = `app/workers_credits?page_size=${data.page_size}&page=${data.page}`

  url += data.search.length? `&search=${data.search}` : ''

  url += data.company_id? `&company_id=${data.company_id}` : `&company_id=all`

  url += data.role >= 0? `&role=${data.role}` : ``

  return instance.get(url)
    .then(response => response.data)
}

instance.createUser = form => instance.post(`app/register_user`, form)
  .then(response => response.data)

instance.deleteUser = (id) => instance.delete(`app/user/${id}`)
  .then(response => response)

instance.getUser = id => instance.get(`app/user/${id}`)
  .then(response => response)

instance.deleteUserCompany = (user_id, company_id) => {
  let url = `app/delete_userprofile/${user_id}`

  instance.delete(url, {data: {company_id}})
    .then(response => response)
}

instance.updateUser = (id,form) => instance.put(`app/user/${id}`, form)
  .then(response => response.data)


/* CREDITS */
instance.getCreditsMovements = data => {
  let url = `app/credits_movements?page_size=${data.page_size}&page=${data.page}`

  url += data.search.length? `&search=${data.search}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.getCurrentUserCredits = company_id => instance.get(`app/user_credit/${company_id}`)
  .then(response => response.data)

instance.getCreditsForWorker = (company_id, worker_id) => instance.get(`app/credits_for_worker?company_id=${company_id}&worker_id=${worker_id}`)
  .then(response => response.data)

instance.getCompanyCredits = company_id => instance.get(`app/company_credits/${company_id}`)
  .then(response => response.data)

instance.addCompanyCredits = (company_id, form) => instance.post(`app/company_credits/${company_id}`, form)
  .then(response => response.data)

instance.addCreditsToWorker = (company_id, form) => instance.post(`app/assign_credits/${company_id}`, form)
  .then(response => response.data)


/* COMPANY EPs */
instance.getCompanies = data => {
    let url = `app/company?page_size=${data.page_size}&page=${data.page}`

    url += data.search.length? `&search=${data.search}` : ''

    return instance.get(url)
      .then(response => response.data)
  }

instance.getCompaniesList = () => instance.get(`app/company_list`)
  .then(response => response.data)

instance.createCompany = form => instance.post(`app/company`, form)
  .then(response => response.data)

instance.getCompany = id => instance.get(`app/company/${id}`)
  .then(response => response.data)

instance.updateCompany = (id, form) => instance.put(`app/company/${id}`, form)
  .then(response => response.data)

instance.deleteCompany = id => instance.delete(`app/company/${id}`)
  .then(response => response.data)


/* MESSAGES EPs */
instance.getMessages = data => {
  let url = `app/message/${data.company_id}/message_list?page_size=${data.page_size}&page=${data.page}`

  url += data.search.length? `&search=${data.search}` : ''
  url += data.status >= 0? `&status=${data.status}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.getOneMessage = id => instance.get(`app/message/${id}`)
  .then(response => response.data)

instance.checkWhatsNumber = data => instance.get(`app/check_whatsapp/${data.company_id}?prefix=${data.prefix}&phone=${data.phone}`)
  .then(response => response.data)

instance.sendMessage = form => instance.post(`app/message/send`, form)
  .then(response => response)

instance.scheduleMessage= form => instance.post(`app/message/program`, form)
  .then(response => response)

instance.getPendantMessages = () => instance.get(`app/message/pending`)
  .then(response => response.data)

instance.exportMessages = (company_id, pagination) => instance.get(`app/message/${company_id}/message_list?export=True`)
  .then(response => response.data)

instance.downloadAttachedFile = file_id => instance.get(`app/download_file/${file_id}`)
  .then(response => response.data)

instance.sendFailedMessage = (message_id, form) => instance.post(`app/message/send/${message_id}`, form)
  .then(response => response)

instance.checkStatusAck = message_id => instance.get(`app/message/check_ack_status/${message_id}`)
.then(response => response.data)

instance.getProgrammedMessageInfo = (message_id, company_id) => instance.get(`app/message/${message_id}/info/${company_id}`)
  .then(response => response.data)

/* MESSAGES TEMPLATES */
instance.getTemplatesList = data => {
  let url = `app/template_per_company/${data.company_id}?page_size=${data.page_size}&page=${data.page}`

  url += data.search.length? `&search=${data.search}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.createTemplate = form => instance.post(`app/template_create`, form)
  .then(response => response.data)

instance.updateTemplate = (id, form) => instance.put(`app/template/${id}`, form)
  .then(response => response.data)

instance.deleteTemplate = id => instance.delete(`app/template/${id}`)
  .then(response => response.data)

/* CHANNELS EPs */
instance.getChannels = (data, filters) => {

  let url = `app/channels/${data.company_id === 'all' ? '4?all_companies=true' : data.company_id}?page_size=${data.page_size}&page=${data.page}`

  url += data.search?.length ? `&search=${data.search}` : ''
  if(data.campaign_creation) {
    url += `&campaign_creation=true`
  }

  if(filters) {
    url += filters.status >= 0? `&status=${filters.status === 0? 'False' : 'True'}` : ''
    url += filters.enabled >= 0? `&enabled=${filters.enabled === 0? 'True' : 'False'}` : ''
  }

  return instance.get(url)
    .then(response => response.data)
}

instance.getChannel = (id) => instance.get(`app/channel/${id}`)
  .then(response => response.data)

instance.checkIfCompanyHasChannel = company_id => instance.get(`app/check_channel/${company_id}`)
  .then(response => response)

instance.checkIfAccessToken = company_id => instance.get(`app/channels/${company_id}/check_access_token`)
  .then(response => response)

instance.updateStatusChannel = channel_id => instance.post(`app/update_status_channel/${channel_id}`)
  .then(response => response)

instance.createChannel = form => instance.post(`app/channels_create`, form)
  .then(response => response)

instance.updateChannel = (id, form) => instance.put(`app/channel/${id}`, form)
  .then(response => response)

instance.deleteChannel = channel_id => instance.delete(`app/channel/${channel_id}`)
  .then(response => response)

instance.getChannel = channel_id => instance.get(`app/channel/${channel_id}`)
  .then(response => response.data)

instance.toggleStatus = id => instance.post(`app/channel_enabled_change/${id}`)
  .then(response => response)

instance.getUltraInstanceSettings = id => instance.get(`app/instance_settings/?channel=${id}`)
  .then(response => response.data)

instance.requestUltrainstanceSettings = form => instance.post(`app/get_instance_settings/`, form)
.then(response => response.data)

instance.updateUltraSettings = id => instance.post(`app/update_channel_num_and_settings/${id}`)
.then(response => response.data)

instance.createLocalInstanceSettings = form => instance.post(`app/instance_settings/`, form)
  .then(response => response.data)

instance.getChannelNumbersHistory = (id, data) => {
  let url = `app/channel_numbers_history?channel=${id}`
  if (data?.page && data?.page_size) url +=
    `&page=${data.page}&page_size=${data.page_size}`

  return instance.get(url)
    .then(response => response.data)
}

/* STATS DASHBOARD EPs */
instance.getStatsData = filters => {
  let url = `app/dasboard_companies`
  // console.log(filters);
  url += filters.company_id == 'all'? '?' : `?company_id=${filters.company_id}`
  url += filters.company_id == 'all'? `date=${filters.date}` : `&date=${filters.date}`

  return instance.get(url)
   .then(response => response.data)
}


instance.getMessagesStats = filters => {
  let url = `app/message_stats`

  url += filters.company_id == 'all'? '?' : `?company_id=${filters.company_id}`
  url += filters.company_id == 'all'? `date=${filters.date}` : `&date=${filters.date}`

  return instance.get(url)
   .then(response => response.data)
}

instance.getSendedMessagesStats = filters => {
  let url = `app/send_message_stats`

  url += filters.company_id == 'all'? '?' : `?company_id=${filters.company_id}`
  url += filters.company_id == 'all'? `date=${filters.date}` : `&date=${filters.date}`

  return instance.get(url)
   .then(response => response.data)
}

instance.getStatsByUser = filters => {
  let url = `app/stats_by_user`

  url += filters.company_id == 'all'? '?' : `?company_id=${filters.company_id}`
  url += filters.company_id == 'all'? `date=${filters.date}` : `&date=${filters.date}`

  return instance.get(url)
   .then(response => response.data)
}

instance.getStatsByCompany = filters => {
  let url = `app/stats_by_company`

  url += filters.company_id == 'all'? '?' : `?company_id=${filters.company_id}`
  url += filters.company_id == 'all'? `date=${filters.date}` : `&date=${filters.date}`

  return instance.get(url)
   .then(response => response.data)
}

instance.getStatsForDashboardCompanies = filters => {
  let url = `app/dasboard_companies`

  url += filters.company_id == 'all'? '?' : `?company_id=${filters.company_id}`
  url += filters.company_id == 'all'? `date=${filters.date}` : `&date=${filters.date}`

  return instance.get(url)
   .then(response => response.data)
}

/* CONTACTS EPs */

instance.getContacts = data => {
  let url = `app/contacts/?page_size=${data.page_size}&page=${data.page}`

  url += data.company_id? `&company_id=${data.company_id}` : ``
  url += data.batch_id? `&batch=${data.batch_id}` : ``
  url += (data.search && data.search.length) ? `&search=${data.search}` : ''
  url += data.status >= 0? `&status=${data.status}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.createContact = (form) => instance.post(`app/contacts/`, form)
  .then(response => response)

instance.deleteContact = (id) => instance.delete(`app/contacts/${id}`)
  .then(response => response)

instance.exportContacts = (id) => instance.post(`app/contacts/create_export_export_contacts_by_company/${id}`)
  .then(response => response.data)

instance.downloadContacts = (id) => instance.get(`app/contacts/download_export_contacts_by_company/${id}`)
  .then(response => response.data)

instance.updateContact = (id, form) => instance.patch(`app/contacts/${id}/`, form)
  .then(response => response)

instance.contactImport = (form) => instance.post(`app/contacts/import`, form)
  .then(response => response)

instance.exportCsvSample = () => instance.get(`app/contacts/download_sample`)
  .then(response => response.data)

instance.contactsCheckAvailableExports = (id) => instance.get(`app/contacts/check_available_export_contacts_by_company/${id}`)
  .then(response => response.data)

  instance.getContactEmail = (id) => instance.get(`app/contacts/${id}/get_email`)
  .then(response => response)

/* CONTACT BATCHES EPs */
instance.getContactBatches = data => {
  let url = `app/contacts_batches/?page_size=${data.page_size}&page=${data.page}`

  url += data.company_id? `&company=${data.company_id}` : ``
  url += data.search?.length ? `&search=${data.search}` : ''
  url += data.status >= 0? `&status=${data.status}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.getContacBatch = (id) => instance.get(`app/contacts_batches/${id}/`)
  .then(response => response)

instance.deleteContactBatch = (id) => instance.delete(`app/contacts_batches/${id}/`)
  .then(response => response)

instance.createContactBatch = (form) => instance.post(`app/contacts_batches/`, form)
  .then(response => response)

instance.getSyncErrorList = (id, data) => {
  let query = '?'
  
  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`
  
  if (data?.search && data?.search.length) query +=
    query.length >1?
      `&search=${data.search}` : `search=${data.search}`
  
  return instance.get(`app/contacts_batches/${id}/sync_error_logs/${query.length > 1? query : ''}`)
    .then(response => response.data)
}

/* TAGS EPs */

instance.getTags = data => {
  let url = `app/tags/get?page_size=${data.page_size}&page=${data.page}`

  url += data.company_id? `&company_id=${data.company_id}` : ``
  url += data.search?.length ? `&search=${data.search}` : ''
  url += data.status >= 0? `&status=${data.status}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.deleteTag = (id) => instance.delete(`app/tags/delete/${id}`)
  .then(response => response)

instance.createTag = (form) => instance.post(`app/tags/create`, form)
  .then(response => response)

instance.updateTag = (id, form) => instance.put(`app/tags/update/${id}`, form)
  .then(response => response)

instance.getTagContacts = (data) => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  return instance.get(`app/tags/${data.id}/get_contacts${query.length > 1? query : ''}`)
    .then(response => response.data)
}

/* CAMPAIGNS EPs */

instance.getCampaigns = (data, filters) => {
  let url = `app/campaign?page_size=${data.page_size}&page=${data.page}`

  url += data.company_id? `&${data.company_id === 'all' ? 'all' : 'company_id'}=${data.company_id === 'all' ? 'true' : data.company_id}` : ``
  url += data.search.length? `&search=${data.search}` : ''
  url += filters.status >= 0? `&status=${filters.status === 5 ? 'sending' : filters.status}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.deleteCampaign = (id) => instance.delete(`app/campaign/${id}`)
  .then(response => response)

instance.createCampaign = (form) => instance.post(`app/campaign/create`, form)
  .then(response => response)

instance.updateCampaignStatus = (id, status) => instance.post(`app/campaign/${id}/update_status`, status)
  .then(response => response)

instance.getCampaign = (id) => instance.get(`app/campaign/${id}/get`)
  .then(response => response.data)

instance.copyCampaign = (form, id) => instance.post(`app/campaign/${id}/copy`, form)
  .then(response => response)

instance.getCampaignSendedMessages = (id) => instance.get(`app/sended_messages/${id}`)
  .then(response => response.data)

instance.getCampaignPendingMessages = (id) => instance.get(`app/pending_messages/${id}`)
  .then(response => response.data)

instance.getCampaignFailedMessages = (id) => instance.get(`app/failed_messages/${id}`)
  .then(response => response.data)

instance.getCampaignMessageList = (id, data) => {
  let url = `app/message_campaign/${id}?page_size=${data.page_size}&page=${data.page}`

  url += data.search.length? `&search=${data.search}` : ''
  url += data.status >= 0? `&status=${data.status}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.getFailedMessagesCause = (id, data) => {
  let url = `app/cause_failed_messages/${id}?page_size=${data.page_size}&page=${data.page}`

  return instance.get(url)
    .then(response => response)
}

instance.exportFailedMessages = data => {
  let url = `app/campaign/export_failed_message/${data.id}`

  url += data.failed_not_whatsapp === true ? `?failed_not_whatsapp=True` : ''
  url += data.failed_not_check_whatsapp === true ? `?failed_not_check_whatsapp=True` : ''

  return instance.get(url)
    .then(response => response)
}

instance.resendFailedMessages = id => instance.get(`app/resend_message_failed/${id}`)
  .then(response => response)

instance.createExportFailedMessages = id => instance.post(`app/campaign/create_export_failed_message/${id}`)
  .then(response => response)

instance.failedMessagesCount = id => instance.get(`app/campaign/count_failed_messages/${id}`)
  .then(response => response.data)

instance.toResendCount = id => instance.get(`app/campaign/check_count_message_failed_to_resend/${id}`)
  .then(response => response.data)
instance.previewMessage = (form) => instance.post(`app/campaign/preview_dynamic_message`, form)
  .then(response => response)

instance.createCampaignMessagesStatusFile = id => instance.post(`app/campaign/export_campaign_messages_sending/${id}`)
  .then(response => response)

instance.updateMessagesStatus = id => instance.post(`app/campaign/${id}/update_status_messages`)
  .then(response => response)

/* RIPENER TEXT EPs */

instance.getRipenerTextList = (data) => {
let query = '?'

if (data?.page && data?.page_size) query +=
  query.length >1?
    `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

if (data?.search && data?.search.length) query +=
  query.length >1?
    `&search=${data.search}` : `search=${data.search}`

if (data?.only_active) query +=
  query.length >1?
    `&only_active=True` : `only_active=True`

  return instance.get(`${first_path}text_ripener/list${query.length > 1? query : ''}`)
  .then(response => response.data)
}

instance.createRipenerText = (form) => instance.post(`${first_path}text_ripener/create`, form)
  .then(response => response)

instance.updateRipenerText = (form, id) => instance.put(`${first_path}text_ripener/update/${id}`, form)
  .then(response => response)

instance.deleteRipenerText = (id) => instance.delete(`${first_path}text_ripener/delete/${id}`)
  .then(response => response)

instance.ripenerTextImport = (form) => instance.post(`${first_path}text_ripener/import`, form)
  .then(response => response)

instance.exportModelFile = () => instance.get(`${first_path}text_ripener/download_csv_ripener_text_sample`)
  .then(response => response.data)

/* BLACKLIST EPs */

instance.getBlacklist = (data) => {
  let query = '?'

  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.search && data?.search.length) query +=
    query.length >1?
      `&search=${data.search}` : `search=${data.search}`

  if (data?.company_list !== 'all') query +=
    query.length >1?
      `&company_list=[${data.company_list}]` : `company_list=[${data.company_list}]`

  if (data?.company_list === 'all') query +=
    query.length >1?
      `&company_list=[]` : `company_list=[]`

  return instance.get(`${first_path}blacknumber/list${query.length > 1? query : ''}`)
    .then(response => response.data)
}

instance.createBlackNumber = form => instance.post(`${first_path}blacknumber/create`, form)
  .then(response => response)

instance.getBlackNumber = id => instance.get(`${first_path}blacknumber/get/${id}`)
  .then(response => response.data)

instance.updateBlackNumber = (id, form) => instance.patch(`${first_path}blacknumbers/${id}/`, form)
  .then(response => response)

instance.deleteBlackNumber = id => instance.delete(`${first_path}blacknumber/delete/${id}`)
  .then(response => response)

/* RIPENER EPs */
instance.getRipenerList = (data) => {
  let url = `${first_path}ripener/list?page_size=${data.page_size}&page=${data.page}`

  url += data.search?.length? `&search=${data.search}` : ''

  return instance.get(url)
    .then(response => response.data)
}

instance.getRipener = (id) => instance.get(`${first_path}ripener/get/${id}`)
  .then(response => response.data)

instance.createRipener = (form) => instance.post(`${first_path}ripener/create`, form)
  .then(response => response)

instance.deleteRipener = id => instance.delete(`${first_path}ripener/delete/${id}`)
  .then(response => response)

instance.updateRipenerStatus = (id, status) => instance.post(`${first_path}ripener/${id}/update_status`, status)
  .then(response => response)

instance.getRipenerMessageList = (id, data) => {
  let query = '?'

if (data?.page && data?.page_size) query +=
  query.length >1?
    `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

if (data?.search && data?.search.length) query +=
  query.length >1?
    `&search=${data.search}` : `search=${data.search}`

if (data?.status >= 0) query+=
  query.length >1?
  `&status=${data.status}` : `status=${data.status}`


  return instance.get(`${first_path}ripener/messages/${id}${query.length > 1? query : ''}`)
  .then(response => response.data)
}

instance.getRipenerFailedMessages = (id) => instance.get(`${first_path}ripener/failedmessage/${id}`)
  .then(response => response.data)

/* GOOGLE EPs */

instance.saveGoogleCredentials = (id, form) => instance.post(`app/company/${id}/save_google_credentials/`, form)
  .then(response => response)

instance.revokeGoogleCredentials = (id) => instance.post(`app/company/${id}/revoke_google_credentials/`,)
  .then(response => response)

instance.googleSyncBatches = (id) => instance.post(`app/contacts_batches/${id}/force_google_sync/`,)
  .then(response => response)

instance.checkIfCompanyHasGoogle = company_id => instance.get(`app/company/${company_id}/has_google_account/`)
  .then(response => response)

/* EVENT EPs */

instance.createEvent = (form) => instance.post(`app/google_calendar_events/`, form,)
  .then(response => response)

instance.createCampaignEvent = (form) => instance.post(`app/campaign/validate_calendar_quota/`, form,)
  .then(response => response)

instance.getEventsList = (data) => {
  let query = '?'
  
  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.company_id) query +=
    query.length >1?
      `&company=${data.company_id}` : `company=${data.company_id}`
  
  if (data?.search && data?.search.length) query +=
    query.length >1?
      `&search=${data.search}` : `search=${data.search}`
  
  if (data?.status >= 0) query+=
    query.length >1?
    `&status=${data.status}` : `status=${data.status}`
  
  
  return instance.get(`app/google_calendar_events${query.length > 1? query : ''}`)
    .then(response => response.data)
}

instance.getCampaignEvents = (data) => {
  let query = '?'
  
  if (data?.page && data?.page_size) query +=
    query.length >1?
      `&page=${data.page}&page_size=${data.page_size}` : `page=${data.page}&page_size=${data.page_size}`

  if (data?.company_id) query +=
    query.length >1?
      `&${data.company_id === 'all' ? 'all' : 'company'}=${data.company_id === 'all' ? 'true' : data.company_id}` : `${data.company_id === 'all' ? 'all' : 'company'}=${data.company_id === 'all' ? 'true' : data.company_id}`
  
  if (data?.search && data?.search.length) query +=
    query.length >1?
      `&search=${data.search}` : `search=${data.search}`
  
  if (data?.status >= 0) query+=
    query.length >1?
    `&status=${data.status}` : `status=${data.status}`
  
  
  return instance.get(`app/event_campaigns${query.length > 1? query : ''}`)
    .then(response => response.data)
}

instance.getCampaignEvent = (id) => instance.get(`app/event_campaigns/${id}`)
  .then(response => response.data)

instance.getCampaignEventContacts = (id, data) => {
  let url = `app/campaign/get_event_campaign_contacts/${id}?`
  if (data?.page && data?.page_size) url +=
    `page=${data.page}&page_size=${data.page_size}`

  if (data?.search) url +=
    `&search=${data.search}`

  return instance.get(url)
    .then(response => response.data)
}

/* VCF FILES EPs */

instance.getVcfFiles = (data, filters) => {
  let url = `app/vcf_file?`
  if (data?.page && data?.page_size) url +=
    `page=${data.page}&page_size=${data.page_size}`

  if (data?.search) url +=
    `&search=${data.search}`

  if (data?.company_id) url +=
    `&company=${data.company_id}`

  if(filters) {
    url += filters.status >= 0? `&status=${filters.status === 0? 'False' : 'True'}` : ''
    url += filters.enabled >= 0? `&enabled=${filters.enabled === 0? 'True' : 'False'}` : ''
  }

  return instance.get(url)
    .then(response => response.data)
}

instance.createVcfFile = (form) => instance.post(`app/vcf_file`, form)
  .then(response => response)

instance.deleteVcfFile = (id) => instance.delete(`app/vcf_file/${id}`,)
  .then(response => response)

instance.getVcfCreatingFlag = () => instance.get(`app/vcf_file_creation`)
  .then(response => response.data)

Vue.prototype.$api = instance

export default instance
