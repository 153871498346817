export default function auth({ next, router }) {
  const session = localStorage.getItem('session') || sessionStorage.getItem('session')
  if (!session) {
    return router.push({ name: 'auth-login' });
  } else {
    const parsedSession = JSON.parse(session)
    if (parsedSession.user.role !== 0) {
      return router.push({ name: 'home' })
    }
  }
  return next();
}