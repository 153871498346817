<template>
  <layout-content-vertical-nav :nav-menu-items="navMenuItemsToRender">
    <slot></slot>

    <!-- Slot: Navbar -->
    <template #navbar="{ toggleVerticalNavMenuActive }"> <!-- #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }" -->
      <div
        class="navbar-content-container"
        :class="{'expanded-search': shallShowFullSearch}"
      >
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <v-icon
            v-if="$vuetify.breakpoint.mdAndDown"
            class="me-3"
            @click="toggleVerticalNavMenuActive"
          >
            {{ icons.mdiMenu }}
          </v-icon>
          <!-- <app-bar-search
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :filter="searchFilterFunc"
            :search-query.sync="appBarSearchQuery"
            @update:shallShowFullSearch="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          ></app-bar-search> -->
        </div>


        <div class="d-flex align-center ml-auto" style="max-width: 18rem">

          <v-select
            :items="company_options"
            hide-details="true"
            v-model="selectedCompany"
            label="Empresa seleccionada"
            outlined
            dense
            @change="changeCompany"
          >
            <template v-slot:prepend-item>
              <v-list-item>

                <v-list-item-content>
                  <v-text-field
                    :prepend-inner-icon="icons.mdiMagnify"
                    placeholder="Buscar empresa"
                    dense
                    hide-details="true"
                    @keyup="filterCompanyList()"
                    v-model="companySearcherInput"
                  >

                  </v-text-field>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>

          </v-select>

        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-space-between">
        <span style="display: none">
          COPYRIGHT &copy; {{ new Date().getFullYear() }}
          <a
            href="https://letscode.ar"
            class="text-decoration-none"
          >
            Let's Code
          </a>
          <span class="d-none d-md-inline">, All rights Reserved</span>
        </span>
        <div class="align-center d-none d-md-flex">
          <!-- <span>Hand-crafted &amp; Made with</span> -->
          <!-- <v-icon
            color="error"
            class="ms-2"
          >
            {{ icons.mdiHeartOutline }}
          </v-icon> -->
        </div>
      </div>
    </template>
  </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical/index-for-company-admin'
import navMenuItemsAdmin from '@/navigation/vertical/index-for-admin.js'
import navMenuItemsWorker from '@/navigation/vertical/index-for-worker.js'

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'

import { mdiMenu, mdiHeartOutline, mdiMagnify } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'

import { ref, watch } from '@vue/composition-api'

export default {
  data: () => {
    return {
      selectedCompany: null,
      companiesFiltered: [],
      companySearcherInput: '',
    }
  },
  mounted() {
    // setTimeout(() => {
      this.getCompaniesList()
    // }, 250);
  },
  methods: {
    filterCompanyList() {

      const filtered = this.allCompaniesData.filter(el => {
        const company_name = el.name.toUpperCase()
        const filter = this.companySearcherInput.toUpperCase()
        return company_name.includes(filter)
      })
      // console.log(this.allCompaniesData)
      this.companiesFiltered = filtered

    },
    async getCompaniesList() {
      const companies = await this.$api.getCompaniesList()
      this.$store.dispatch('session/setAllCompaniesList', companies)

      const selectedCompanyInLocalStorage = localStorage.getItem('selected_company') || sessionStorage.getItem('selected_company')


      if (selectedCompanyInLocalStorage === '"all"') {

        this.selectedCompany =
          this.routesWithAllMembersOption.includes(this.currentPageName)
            ? 'all'
            : this.company_options[this.getUserRole !== 2? 0 : this.company_options.length - 1].value

      } else {

        this.selectedCompany =
          selectedCompanyInLocalStorage
            ? Number(selectedCompanyInLocalStorage)
            : this.company_options[this.getUserRole !== 2? 0 : this.company_options.length - 1]?.value

      }

      this.$store.dispatch('session/setSelectedCompany', this.selectedCompany)
    },
    changeCompany(company_id) {
      this.companySearcherInput = ''
      this.companiesFiltered = []
      this.$store.dispatch('session/setSelectedCompany', company_id)
    }
  },
  computed: {
    company_options() {
      const companies = this.companiesFiltered.length? this.companiesFiltered : this.allCompaniesData
      const companies_length = companies.length
      const parsedForVSelect =  this.$route.name === 'campaigns' && this.getUserRole === 0
                                ? [{text: 'Todas las empresas', value: 'all'}]
                                : this.routesWithAllMembersOption.includes(this.$route.name) && this.getUserRole !== 2
                                ? [{text: 'Todas las empresas', value: 'all'}]
                                : []

      for (let i = 0; i < companies_length; i++) {
        parsedForVSelect.push({
          text: companies[i].name,
          value: companies[i].id,
        })
      }
      return parsedForVSelect
    },

    allCompaniesData() { return this.$store.getters['session/getAllCompaniesList']() },
    getUserRole() { return this.$store.getters['session/getUserRole']() },
    currentPageName() { return this.$route.name },
    routesWithAllMembersOption() { return ['users', 'stats', 'blacklist', 'ripener', 'channels'] }

  },
  watch: {
    currentPageName(pageValue) {
      // console.log('this route: ', pageValue);
      if (pageValue) {
        if (!this.routesWithAllMembersOption.includes(pageValue)) {
          if (this.selectedCompany === 'all' && pageValue === 'campaigns' && this.getUserRole === 0) this.selectedCompany = 'all'
          else if (this.selectedCompany === 'all') this.selectedCompany = this.company_options[this.company_options.length - 1].value ?? this.company_options[0].value
          // console.log('this selected: ', this.selectedCompany);

          this.changeCompany(this.selectedCompany)
        } else if (pageValue === 'ripener' || pageValue === 'blacklist'){
          this.changeCompany('all')
          this.selectedCompany = 'all'
        }
      }
    },

  },
  components: {
    LayoutContentVerticalNav,

    // App Bar Components
    AppBarSearch,
    AppBarUserMenu,
  },

  setup() {
    const $vuetify = getVuetify()

    // Search
    const appBarSearchQuery = ref('')
    const shallShowFullSearch = ref(false)
    const maxItemsInGroup = 5
    const totalItemsInGroup = ref({
      pages: 0,
      files: 0,
      contacts: 0,
    })
    watch(appBarSearchQuery, () => {
      totalItemsInGroup.value = {
        pages: 0,
        files: 0,
        contacts: 0,
      }
    })

    // NOTE: Update search function according to your usage
    const searchFilterFunc = (item, queryText, itemText) => {
      if (item.header || item.divider) return true

      const itemGroup = (() => {
        if (item.to !== undefined) return 'pages'
        if (item.size !== undefined) return 'files'
        if (item.email !== undefined) return 'contacts'

        return null
      })()

      const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

      if (isMatched) {
        if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
        else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
        else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
      }

      return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
    }

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
        toggleVerticalNavMenuActive()
      }
    }

    let navMenuItemsToRender = navMenuItems
    const session = localStorage.getItem('session') || sessionStorage.getItem('session')
    if (session) {
      const parsedSession = JSON.parse(session)
      if (parsedSession.user.role === 0) {
        navMenuItemsToRender = navMenuItemsAdmin
      } else if (parsedSession.user.role === 2) {
        navMenuItemsToRender = navMenuItemsWorker
      }
    }

    return {
      navMenuItemsToRender,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchQuery,
      shallShowFullSearch,
      appBarSearchData,
      searchFilterFunc,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiMagnify
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}

</style>
